import "./App.css";
import React, { useState } from "react";
import privacy from "./privacy";
import imprint from "./imprint";

function App() {
  const isPrivacyModalOpen = window.location.href.includes("privacy");
  const isImprintModalOpen = window.location.href.includes("imprint");

  return (
    <div className="App">
      <div className="background-pattern">
        <div className="background-pattern-overlay" />
      </div>
      <header className="App-header">
        <div className="card card-front">
          <div className="card-header">
            <div />
            <div className="card-logo"></div>
            <div className="card-logo-outlined-1"></div>
            <div className="card-logo-outlined-2"></div>
          </div>
          <div className="card-titles">
            <h1 className="card-title">MTS Immo</h1>
            <div className="card-subtitles">
              <div>Tim Philipp Höhnel</div>
              <div className="card-contacts">
                <div>info@mts.immo</div>
                <div>+49 163 541 8429</div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <a href="/privacy">Datenschutz</a>
          {" | "}
          <a href="/imprint">Impressum</a>
        </div>
        {(isPrivacyModalOpen || isImprintModalOpen) && (
          <div
            className="modal-container"
            onClick={() => (window.location.href = "/")}
          >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <div
                  className="icon clickable"
                  onClick={() => (window.location.href = "/")}
                />
              </div>
              <div className="modal-text">
                {isPrivacyModalOpen ? privacy : imprint}
              </div>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
